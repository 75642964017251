<template>
  <div>

    <div class="container">
      <div class="row px-3 text-center">

        <div class="col-3 py-2 border" :class="step_class[0]"><i class="fad fa-box-open"></i><span class="pl-2">Entrega y Facturación</span></div>
        <div class="col-3 py-2 border" :class="step_class[1]"><i class="fad fa-wallet"></i><span class="pl-2">Forma de pago</span></div>
        <div class="col-3 py-2 border" :class="step_class[2]"><i class="fad fa-box-heart"></i><span class="pl-2">Confirmar</span></div>
        <div class="col-3 py-2 border" :class="step_class[3]"><i class="fad fa-box-check"></i><span class="pl-2">Resumen</span></div>

      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      step_class: ''
    }
  },
  props: [ 'step' ],
  mounted() {
    this.step_class = [
      (this.step == 1) ? 'bg-primary text-white' : 'bg-white text-muted',
      (this.step == 2) ? 'bg-primary text-white' : 'bg-white text-muted',
      (this.step == 3) ? 'bg-primary text-white' : 'bg-white text-muted',
      (this.step == 4) ? 'bg-primary text-white' : 'bg-white text-muted'
    ]
  },
}
</script>